import _controller from "./controller";
import _process from "process";
var exports = {};
var process = _process;
var _require = _controller,
    read_File_ES2016 = _require.read_File_ES2016,
    from_string_ES2016 = _require.from_string_ES2016,
    read_File_CommonJS = _require.read_File_CommonJS,
    from_string_CommonJS = _require.from_string_CommonJS;
/**
 * 
 * @file index.js is the root file for this package
 */

/**
 * 
 * @param {String} path - Stringed path to clients svg file, or file itself
 * @returns {Promise}
 */

exports = function (path) {
  var file = typeof path === "string" ? path : path.string;

  if (process.browser) {
    var isImgPath = file.match(/data:image\/svg\+xml/g);

    if (isImgPath) {
      // Client supplies svg img file
      return read_File_ES2016(file);
    } else {
      // Client supplies stringed svg
      return from_string_ES2016(file);
    }
  } else {
    var isSVGstring = file.match(/<\/svg>/g); // If path is svg string returns true

    if (isSVGstring) {
      return from_string_CommonJS(file);
    }

    return read_File_CommonJS(file);
  }
};

export default exports;