import * as _http2 from "http";

var _http = "default" in _http2 ? _http2.default : _http2;

var exports = {};
// @ts-nocheck
var http = _http;
/**
 * For use with NodeJS only
 * Displays new converted svg to a usable svg react components.
 * @param {string|object} svg 
 */

var openFile = function openFile(svg) {
  var text = {
    "Content-Type": "text/plain; charset=utf-8"
  };
  var html = {
    "Content-Type": "text/html"
  };
  var error = svg.error;
  var content_type;
  var content;

  if (error) {
    content_type = html;
    content = svg.error;
  } else {
    content_type = text;
    content = svg;
  }

  var requestListener = function requestListener(req, res) {
    res.writeHead(200, content_type);
    res.end(content);
  };

  var server = http.createServer(requestListener);
  server.listen(8002);
  console.log("Open browswer http://localhost:8002/");
};

exports = openFile;
export default exports;