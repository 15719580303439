import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _slicedToArray3 from "@babel/runtime/helpers/slicedToArray";

var _slicedToArray = "default" in _slicedToArray3 ? _slicedToArray3.default : _slicedToArray3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStyles = void 0;

var _slicedToArray2 = _interopRequireDefault(_slicedToArray);

var updateStyles = function updateStyles(styles) {
  var catenate = function catenate(match, p1, p2, p3) {
    return p1 + p3.toUpperCase();
  }; // First replace any style property dashes and concatenate first letter with it


  var newStyleString = styles.replace(/(.?)(-)(.)/ig, catenate); // Second make new style object out of replaced string

  return newStyleString.split(";").reduce(function (obj, style) {
    var _style$split = style.split(":"),
        _style$split2 = (0, _slicedToArray2["default"])(_style$split, 2),
        key = _style$split2[0],
        value = _style$split2[1];

    if (value) obj[key.trim()] = value.trim();
    return obj;
  }, {});
};

exports.updateStyles = updateStyles;
export default exports;